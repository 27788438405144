import { LAST_SEARCH_KEY, StoredWizardState } from '@/components/ui/wizard/Wizard'
import { isInTestGroup } from '@/lib/ab-tests/test-variants'
import { TestVariant } from '@/lib/ab-tests/test-definitions'

export const TIME_SEARCH_PARAMETERS_UPDATED = 'TIME_SEARCH_PARAMETERS_UPDATED_EVENT_TYPE'

export function loadSavedSearch(): StoredWizardState | undefined {
  const dontReloadSavedSearchDate =
    isInTestGroup(TestVariant.ReloadSavedSearchData) || window.location.pathname.includes('search')

  if (dontReloadSavedSearchDate) {
    return
  }

  try {
    const savedSearchData = JSON.parse(
      window.localStorage.getItem(LAST_SEARCH_KEY) || '{}',
    ) as StoredWizardState

    if (!savedSearchData.lastUpdated) {
      return undefined
    }

    if (!savedSearchData.travelPeriodSearchTerm) {
      delete savedSearchData.flexibleTravelPeriodSelected
    }

    return savedSearchData
  } catch {
    return
  }
}
