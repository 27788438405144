'use client'

import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { onMobile, onMobileOrTablet } from '@/lib/utils/utils'
import { ActionCreatorWithOptionalPayload, ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import CloseButton from '@/components/elements/CloseButton'
import { WizardStep } from '@/components/ui/wizard/Wizard'
import { WIZARD_INPUT_BLUR, WIZARD_INPUT_KEY_PRESSED } from '@/lib/sharedConstants'
import { DeleteIcon, SelectIcon } from '@/components/common/InputIcons'
import { useTranslations } from 'next-intl'

interface WizardInputPlaceHolderProps extends Partial<WizardInputProps> {
  searchPage: boolean
  bookmarksPage?: boolean
  highlighted?: boolean
  resetOrClear?: () => void
  focused?: () => void
  blured?: () => void
  icon: WizardInputIcon | undefined
  additionalClassName: string
  mobileTitle: string
  label: string
  currentlyActive?: boolean
  hasFocus?: boolean
  activeStep?: number
  close?: () => void
  search?: (searchTearm: string) => void
  onClick?: () => void
  hideRightInputBorder?: boolean
  withBorders?: boolean
  alwaysVisible?: boolean
}

export interface WizardInputProps {
  bookmarksPage?: boolean
  searchPage: boolean
  mobileTitle: string
  placeholderDesktop: string
  placeholderMobile: string
  placeholderSearchPage: string
  name: string
  label: string
  step: number
  detailsBox: ReactNode
  searchTerm: string
  setSearchTerm?: ActionCreatorWithOptionalPayload<string>
  selectedValues: string
  resetInput: ActionCreatorWithoutPayload
  disableInputField?: boolean
  additionalClassName: string
  hideInputOnMobile?: boolean
  searchWizard: boolean | undefined
  scrollIntoViewOnLoad?: boolean
  hideRightInputBorder?: boolean
  withBorders?: boolean
  highlighted?: boolean
  alwaysVisible?: boolean
}

export enum WizardInputIcon {
  CHEVRON_DOWN = 'chevron-down',
  DELETE = 'delete',
}

const WIZARD_INPUT_STYLES_SEARCH_PAGE =
  ' wd:shadow-none wd:m-0 bg-transparent wd:inline-flex wd:w-full' +
  ' cursor-black ' +
  " last:rounded-r-wd truncate focus:outline-none wm:after:content-['']" +
  ' wm:after:absolute wm:after:top:0 wm:after:w-[120px] wm:after:h-[124px] wm:after:bg-red wm:after:z-40 wm:after:block' +
  ' h-full wd:ring-transparent wm:w-full ' +
  ' text-left wd:pr-10 rounded-wd wd:px-3 relative wm:after:content-[""]'

const WIZARD_INPUT_STYLES =
  'placeholder:text-white wdl:placeholder:text-black-34 border-0 wdl:shadow-none wdl:m-0 bg-transparent wdl:inline-flex wdl:w-full' +
  ' cursor-black wdl:border-l-0 wdl:border-t-0 wdl:border-b-0 wdl:rounded-none border-1' +
  " last:rounded-r-wd truncate focus:outline-none wml:after:content-['']" +
  ' wml:after:absolute wml:after:top:0 wml:after:w-[120px] wml:after:h-[124px] wml:after:bg-red wml:after:z-40 wml:after:block' +
  ' h-full focus:border-[0px] wdl:ring-transparent wml:w-full' +
  ' text-left wdl:pr-10 rounded-wd wdl:px-3 relative wml:after:content-[""]'

function dontPublishEvents(searchWizard: boolean): boolean {
  return (!searchWizard && onMobileOrTablet()) || (!searchWizard && onMobileOrTablet())
}

function publishWizardInputKeyPressedEvent(
  key: 'Enter' | 'ArrowUp' | 'ArrowDown',
  searchWizard: boolean,
): void {
  if (dontPublishEvents(searchWizard)) {
    return
  }

  window.dispatchEvent(
    new CustomEvent<{ key: 'Enter' | 'ArrowUp' | 'ArrowDown' }>(WIZARD_INPUT_KEY_PRESSED, {
      detail: { key },
    }),
  )
}

function publishWizardInputBlurEvent(searchWizard: boolean): void {
  if (dontPublishEvents(searchWizard)) {
    return
  }

  window.dispatchEvent(new CustomEvent(WIZARD_INPUT_BLUR))
}

export default function WizardInputPlaceHolder(props: WizardInputPlaceHolderProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null)
  const wrapperRef = useRef<HTMLInputElement>(null)
  const [showMobilePlaceholders, setShowMobilePlaceholders] = useState(false)
  const mapLayoutABTestGroupB = true
  // useWithinGroupB(Experiment.SearchWithoutMap)
  const translate = useTranslations('Wizard')
  const [inputFocused, setInputFocused] = useState(false)
  const t = useTranslations('Wizard')

  useEffect(() => {
    setShowMobilePlaceholders(onMobile())
  }, [])

  useEffect(() => {
    if (
      props.scrollIntoViewOnLoad &&
      wrapperRef.current &&
      props.activeStep !== undefined &&
      props.activeStep !== WizardStep.SEARCH &&
      ((props.searchWizard && !onMobileOrTablet()) || (!props.searchWizard && !onMobile()))
    ) {
      setTimeout(scrollIntoView, 100)
    }
  }, [wrapperRef.current, props.activeStep, props.scrollIntoViewOnLoad])

  useEffect(() => {
    if (inputRef.current && props.activeStep === props.step) {
      inputRef.current.focus()
    }
  }, [props.activeStep, props.step])

  useEffect(() => {
    function listenToKeyboardEvents(event: KeyboardEvent): void {
      if (inputFocused) {
        if (event.key === 'Enter' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
          publishWizardInputKeyPressedEvent(event.key, !!props.searchWizard) // Call the custom event handler
          event.preventDefault()
        }
      }
    }

    inputRef.current?.addEventListener('keydown', listenToKeyboardEvents)

    return () => {
      inputRef.current?.removeEventListener('keydown', listenToKeyboardEvents)
    }
  }, [inputFocused])

  function showSearchTerm(): boolean {
    return !!(props.hasFocus || props.currentlyActive)
  }

  const LANDING_PAGE = classNames(
    'wml:h-full wml:focus:ring-primary wml:ring-inset wdl:focus:ring-0 focus:outline-[0px] wdl:border-r-[1px] wml:border-[0px] wml:focus:ring-primary wml:ring-inset wdl:ring-transparent wml:w-full wml:shadow-[0_7px_12px_rgba(0,0,0,0.06);] wdl:h-[72px] w-auto wdl:relative wml:fixed wml:left-0 wml:right-0 wml:top-0 wml:bottom-0',
    props?.additionalClassName,
    { 'wml:hidden wml:rounded-md': !props.currentlyActive },
  )

  const SEARCH_PAGE = classNames(
    'wm:h-full ring-inset focus:ring-0 focus:outline-[0px] bg-transparent wd:relative h-[42px] overflow-hidden wd:overflow-visible wd:bg-transparent',
    props?.additionalClassName,
    {
      'md:w-[calc(calc(100vw-32px)/3)] lg:w-[227px] xl:w-[228px]': !(
        mapLayoutABTestGroupB && props.searchPage
      ),
      'wm:hidden': props.activeStep === undefined && !props.alwaysVisible,
    },
  )

  function placeHolder(): string {
    if (props.searchWizard) {
      return props.placeholderSearchPage as string
    }

    return (showMobilePlaceholders ? props.placeholderMobile : props.placeholderDesktop) as string
  }

  function scrollIntoView(): void {
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }

  function focused(): void {
    if (props.focused) {
      props.focused()
    }

    setInputFocused(true)
  }

  function resetOrClear(): void {
    if (props.resetOrClear) {
      props.resetOrClear()
    }
  }

  return (
    <div
      className={classNames('w-full border-stone-400', {
        'w-full': !mapLayoutABTestGroupB,
        [LANDING_PAGE]: !props.searchWizard,
        [SEARCH_PAGE]: props.searchWizard,
        'wd:border-r-[1px] wm:border-[0px] ':
          props.searchWizard && !props.hideRightInputBorder && !props.highlighted,
        'wm:hidden': props.searchWizard && props.activeStep !== props.step && !props.alwaysVisible,
      })}
      onClick={props.onClick}
    >
      <div
        className={classNames({
          'wd:hidden wm:relative wm:mb-4 wm:bg-white wm:px-[14px] wm:py-[14px] wm:text-2xl wm:font-semibold wm:text-white':
            props.searchWizard,
          'wdl:hidden wml:relative wml:mb-4 wml:bg-white wml:px-[14px] wml:py-[14px] wml:text-2xl wml:font-semibold wml:text-white':
            !props.searchWizard,
          'wm:hidden': props.activeStep === undefined,
        })}
        id="mobile-wizard-step-header"
      >
        <div
          className={classNames({
            'max-w-[calc(100%-22px)] overflow-hidden text-ellipsis wd:hidden wm:text-2xl wm:font-semibold wm:text-black-84':
              props.searchWizard,
            'max-w-[calc(100%-22px)] overflow-hidden text-ellipsis wdl:hidden wml:text-2xl wml:font-semibold wml:text-black-84':
              !props.searchWizard,
          })}
        >
          {t(props.mobileTitle)}
        </div>
        <CloseButton additionalClassNames="absolute right-0 top-[2px]" onClick={props.close} />
      </div>
      {!props.searchWizard && (
        <div
          className={classNames({
            'invisible absolute h-0 text-sm font-medium text-black-54 wd:visible wd:left-3 wd:top-2 wd:h-[80px]':
              props.searchWizard,
            'invisible absolute h-0 text-sm font-medium text-black-54 wdl:visible wdl:left-3 wdl:top-2 wdl:h-[80px]':
              !props.searchWizard,
          })}
          onClick={() => inputRef.current?.focus()}
        >
          {t(props.label)}
        </div>
      )}
      <div
        className={classNames('relative scroll-my-2.5', {
          'wml:hidden': props.hideInputOnMobile && !props.searchWizard,
          'wm:hidden':
            props.hideInputOnMobile && !(props.searchWizard && props.activeStep !== props.step),
          'wdl:h-[74px] wml:h-[54px] wml:pl-[14px] wml:pr-[14px]': !props.searchWizard,
          'rounded wd:h-[40px] wm:h-[54px] wm:pl-[14px] wm:pr-[14px]': props.searchWizard,
          'wml:before:absolute wml:before:bottom-0 wml:before:left-7 wml:before:top-3.5 wml:before:z-40 wml:before:inline-block wml:before:inline-flex wml:before:h-[24px] wml:before:w-[24px] wml:before:bg-contain wml:before:bg-center wml:before:bg-no-repeat wml:before:content-[""] wml:before:icon-search-mobile':
            !props.searchWizard,
          'wm:before:absolute wm:before:bottom-0 wm:before:left-7 wm:before:top-3.5 wm:before:z-40 wm:before:inline-block wm:before:inline-flex wm:before:h-[24px] wm:before:w-[24px] wm:before:bg-contain wm:before:bg-center wm:before:bg-no-repeat wm:before:content-[""] wm:before:icon-search-mobile':
            props.activeStep !== undefined && props.searchWizard,
          'w-full py-0 wm:pl-0 wm:pr-0': props.searchWizard && props.activeStep === undefined,
          'border-primary': props.highlighted,
        })}
        onClick={
          props.searchWizard
            ? undefined
            : () => {
                scrollIntoView()
              }
        }
        ref={wrapperRef}
      >
        <input
          autoComplete="off"
          type="text"
          name={props.name}
          value={(showSearchTerm() ? props.searchTerm : props.selectedValues) || ''}
          className={classNames(
            'font-medium text-stone-700 outline-[0px] focus:ring-0',
            props.searchWizard ? WIZARD_INPUT_STYLES_SEARCH_PAGE : WIZARD_INPUT_STYLES,
            {
              'border-1 border-0 focus:border-[0px] wd:rounded-none wd:border-b-0 wd:border-l-0 wd:border-t-0':
                !props.highlighted && props.searchWizard && !props.withBorders,
              'bg-primary-lightened placeholder:text-black-84 placeholder:opacity-100':
                props.highlighted,
              'placeholder:text-white wd:placeholder:text-black-34':
                props.highlighted && !props.highlighted,
              'caret-white': props.disableInputField,
              'h-[72px]lg:text-lg wdl:pt-7 wdl:text-md wdl:focus:ring-0 wml:h-[54px] wml:border wml:border-[#E8E8E8] wml:pl-11 wml:text-xl  wml:shadow-[0_7px_12px_rgba(0,0,0,0.06);] wml:ring-inset wml:focus:border wml:focus:border-[#E8E8E8] wml:focus:ring-primary ':
                !props.searchWizard,
              'h-[72px] h-full focus:outline-[0px] lg:text-lg wd:h-[40px]  wd:focus:ring-0 wm:h-[54px] wm:border-[1px] wm:border-[#E8E8E8] wm:pl-11 wm:text-xl wm:shadow-[0_7px_12px_rgba(0,0,0,0.06);] wm:ring-inset  wm:focus:border wm:focus:border-[#E8E8E8] wm:focus:ring-primary ':
                props.searchWizard &&
                props.activeStep !== undefined &&
                props.activeStep === props.step &&
                !props.highlighted,
              'focus:scroll-none w-12 px-2 text-lg focus:ring-0':
                props.searchWizard && props.activeStep === undefined,
              'rounded border border-[rgba(0,0,0,0.25)] focus:border-[rgba(0,0,0,0.25)]':
                props.withBorders && !props.highlighted,
              'rounded border border-primary': props.withBorders && props.highlighted,
            },
          )}
          placeholder={placeHolder() ? translate(placeHolder()) : ''}
          onChange={(e) => {
            props.focused && focused()
            if (!props.disableInputField && props.search) {
              props.search(e.target.value)
            }
          }}
          onFocus={() => {
            setTimeout(() => {
              window.document.documentElement.classList.add('keyboardOpen')
            })
            props.focused && focused()
          }}
          onBlur={() => {
            setTimeout(() => window.document.documentElement.classList.remove('keyboardOpen'))
            props.blured && props.blured()
            setInputFocused(false)
            publishWizardInputBlurEvent(!!props.searchWizard)
          }}
          ref={inputRef}
        />
        {props.icon === WizardInputIcon.CHEVRON_DOWN && (
          <SelectIcon additionalClassName="hidden wd:block wdl:block" />
        )}
        {props.icon === WizardInputIcon.DELETE && (
          <DeleteIcon additionalClassName="hidden wd:block wdl:block" />
        )}
        {props.icon === WizardInputIcon.DELETE && (
          <div
            className="absolute bottom-0 right-0 top-0 z-40 inline-block hidden h-full w-10 cursor-pointer wd:block wdl:block"
            onClick={(event) => {
              if (props.onClick) {
                props.onClick()
              } else {
                event.stopPropagation()
                props.icon === WizardInputIcon.CHEVRON_DOWN ? focused() : resetOrClear()
              }
            }}
          ></div>
        )}
      </div>
      <div
        className={classNames('h-full', {
          'wd:absolute': props.searchWizard,
          'wdl:absolute': !props.searchWizard,
        })}
      >
        {props.currentlyActive && props.detailsBox}
      </div>
    </div>
  )
}
