'use client'

import React from 'react'
import classNames from 'classnames'
import ChevronDownIcon from '@/icons/font-awesome/sharp-light/chevron-down.svg'
import CircleXMarkIcon from '@/icons/font-awesome/sharp-light/circle-xmark.svg'

interface InputIconParams {
  additionalClassName?: string
  onClick?: () => void
}

export function SelectIcon(props: InputIconParams) {
  return <ChevronDownIcon className={classNames('h-[13px] absolute fill-stone-700 absolute top-1/2 right-3 transform -translate-y-1/2', props.additionalClassName)}
                    onClick={props.onClick} />
}

export function DeleteIcon(props: InputIconParams) {
  return <CircleXMarkIcon className={classNames('h-[17px] absolute fill-stone-700 absolute top-1/2 right-3 transform -translate-y-1/2', props.additionalClassName)}
                    onClick={props.onClick} />
}