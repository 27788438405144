import React, { ReactElement } from 'react'
import classNames from 'classnames'
import CloseIcon from '../../icons/close.svg'

export default function CloseButton(props: {
  onClick: (() => void) | undefined
  additionalClassNames?: string
  padding?: string
  iconColor?: string
}): ReactElement {
  return (
    <button
      className={classNames(
        props.padding || 'p-4',

        props.additionalClassNames,
      )}
      onClick={props.onClick}
    >
      <CloseIcon className={props.iconColor || 'stroke-[#141414]'} />
    </button>
  )
}
