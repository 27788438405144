'use client'

import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import WizardInputPlaceHolder, { WizardInputIcon } from '@/components/ui/wizard/WizardInputPlaceHolder'
import WizardWrapper from '@/components/ui/wizard/WizardWrapper'
import dynamic from 'next/dynamic'
import { Currency, SearchItem, SearchProps } from '@/types/Page'
import { SearchSection, wizardLabels } from '@/lib/sharedConstants'
import { loadSavedSearch } from '@/components/ui/wizard/wizardUtils'
import { Duration, Month } from '@/types/Search'
import { usePersistedPageLoaded } from '@/lib/hooks'
import { ReactComponentLike } from 'prop-types'

export enum WizardStep {
  DESTINATION,
  TOPIC,
  TRAVEL_PERIOD,
  SEARCH,
}

export type SearchParametersUpdatedEvent = CustomEvent<Record<string, string>>

export const SEARCH_PARAMETERS_UPDATED_EVENT_TYPE = 'searchParametersUpdated'

export const LAST_SEARCH_KEY = 'last-search'

export interface SearchResult {
  type: SearchSection
  results: SearchItem[]
}

export interface SavedWizardState {
  flexibleTravelPeriodSelected?: boolean
  selectedLocationItems: SearchItem[]
  selectedTopicItems: SearchItem[]
  selectedOptionPeriods: Month[] | undefined
  selectedOptionDuration: Duration | undefined
  selectedOptionCalendarFlexibility: Duration | undefined
}

export interface StoredWizardState extends SavedWizardState {
  lastUpdated: string
  travelPeriodSearchTerm: string
  startDate: string | undefined
  endDate: string | undefined
}

export const DESTINATION_INPUT_PROPS = {
  name: 'destination',
  additionalClassName: 'z-40',
}

export const TOPIC_INPUT_PROPS = {
  name: 'Topic',
  additionalClassName: 'z-30',
}

export const TRAVEL_PERIOD_INPUT_PROPS = {
  name: 'period',
  additionalClassName: 'z-20',
  disableInputField: true,
}

export function loadSearchParams(): StoredWizardState | undefined {
  const params = new URLSearchParams(window.location.search)

  try {
    return JSON.parse(new Buffer(params.get('q') || '', 'base64').toString()) as StoredWizardState
  } catch {
    return
  }
}

interface InputProps {
  mobileTitle: string
  label: string
  placeholderDesktop: string
  placeholderMobile: string
  placeholderSearchPage: string
}

interface DummyWizardProps extends SearchProps {
  showWizard: (step?: number) => void
  savedSearchData: StoredWizardState | undefined
  destinationSearchTerm: string
  travelPeriodSearchTerm: string
  topicSearchTerm: string
  setDeleteValue: (index: 0 | 1 | 2) => void
  searchWizard?: boolean
  // showSearchOption: (t: FilterModalType) => void
  bookmarksPage: boolean
  searchPage: boolean
  destinationInputProps: InputProps
  topicInputProps: InputProps
  travelPeriodInputProps: InputProps
}

function DummyWizard(props: DummyWizardProps): ReactElement {
  function getIcon(value: string): WizardInputIcon {
    return value ? WizardInputIcon.DELETE : WizardInputIcon.CHEVRON_DOWN
  }

  return (
      <WizardWrapper
        onMobileEntryClick={() => props.showWizard(0)}
        searchWizard={props.searchWizard}
        search={() => {
          props.showWizard(WizardStep.SEARCH)
        }}
      >
        <WizardInputPlaceHolder
          {...wizardLabels.destinationInputProps}
          {...DESTINATION_INPUT_PROPS}
          selectedValues={props.destinationSearchTerm}
          onClick={() => props.showWizard(0)}
          icon={getIcon(props.destinationSearchTerm)}
          resetOrClear={() => {
            props.setDeleteValue(0)
            props.showWizard()
          }}
          searchWizard={props.searchWizard}
          bookmarksPage={props.bookmarksPage}
          searchPage={props.searchPage}
        />
        <WizardInputPlaceHolder
          {...wizardLabels.topicInputProps}
          {...TOPIC_INPUT_PROPS}
          selectedValues={props.topicSearchTerm}
          onClick={() => props.showWizard(1)}
          icon={getIcon(props.topicSearchTerm)}
          resetOrClear={() => {
            props.setDeleteValue(1)
            props.showWizard()
          }}
          searchWizard={props.searchWizard}
          bookmarksPage={props.bookmarksPage}
          searchPage={props.searchPage}
        />
        <WizardInputPlaceHolder
          {...wizardLabels.travelPeriodInputProps}
          {...TRAVEL_PERIOD_INPUT_PROPS}
          selectedValues={props.travelPeriodSearchTerm}
          onClick={() => props.showWizard(2)}
          icon={getIcon(props.travelPeriodSearchTerm)}
          resetOrClear={() => {
            props.setDeleteValue(2)
            props.showWizard()
          }}
          searchWizard={props.searchWizard}
          bookmarksPage={props.bookmarksPage}
          searchPage={props.searchPage}
        />
      </WizardWrapper>
  )
}

interface RealWizardProps extends SearchProps {
  dummy: ReactElement
  activeStep: number | undefined
  deleteValue: 0 | 1 | 2 | undefined
  searchWizard: boolean
  currency: Currency | undefined
  searchPage: boolean
}

function RealWizard(props: RealWizardProps): ReactElement {
  const WizardImplementation = useMemo(
    () =>
      dynamic(() => import('@/components/ui/wizard/WizardImplementation'), {
        loading: () => props.dummy,
      }),
    [],
  ) as ReactComponentLike

  return <WizardImplementation {...props} />
}

interface WizardProps extends SearchProps {
  travelPeriodInputProps: InputProps
  topicInputProps: InputProps
  destinationInputProps: InputProps
  searchPage: boolean
  searchWizard?: boolean
  currency?: Currency
  bookmarksPage: boolean
}

export function Wizard(props: WizardProps): ReactElement {
  const [realWizardStep, setRealWizardStep] = useState<number | undefined>()
  const [showRealWizard, setShowRealWizard] = useState<boolean>()
  const [savedSearchData, setSavedSearchData] = useState<StoredWizardState | undefined>()
  const [deleteValue, setDeleteValue] = useState<0 | 1 | 2 | undefined>()

  useEffect(() => setShowRealWizard(props.searchWizard), [])

  function travelPeriodSearchTerm(): string {
    if (!savedSearchData) {
      return ''
    }

    if (savedSearchData.startDate) {
      const startDate = new Date(savedSearchData.startDate)
      startDate.setHours(0, 0, 0, 0)
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      if (startDate >= today) {
        return savedSearchData.travelPeriodSearchTerm
      } else {
        return ''
      }
    }

    return savedSearchData.travelPeriodSearchTerm
  }

  function destinationSearchTerm(): string {
    if (props.destinations?.length === 0 && !savedSearchData?.selectedLocationItems) {
      return ''
    }

    return (
      props.destinations?.map((d) => d.label || d.text).join(', ') ||
      savedSearchData?.selectedLocationItems.map((i) => i.label || i.text).join(', ') ||
      ''
    )
  }

  function topicSearchTerm(): string {
    if (props.topics?.length === 0 && !savedSearchData?.selectedTopicItems) {
      return ''
    }

    return (
      props.topics?.map((d) => d.label).join(', ') ||
      savedSearchData?.selectedTopicItems.map((i) => i.label || i.text).join(', ') ||
      ''
    )
  }

  useEffect(() => {
    setSavedSearchData(loadSearchParams() || loadSavedSearch())
  }, [setSavedSearchData])

  usePersistedPageLoaded(() => {
    setSavedSearchData(loadSearchParams() || loadSavedSearch())
  })

  function showWizard(step: number | undefined): void {
    setShowRealWizard(true)
    setRealWizardStep(step)
  }

  const dummy = (
    <DummyWizard
      {...props}
      showWizard={showWizard}
      savedSearchData={savedSearchData}
      travelPeriodSearchTerm={travelPeriodSearchTerm()}
      destinationSearchTerm={destinationSearchTerm()}
      topicSearchTerm={topicSearchTerm()}
      setDeleteValue={setDeleteValue}
      searchWizard={props.searchWizard}
      searchPage={props.searchPage}
    />
  )

  return (
    <>
      {showRealWizard &&
      (realWizardStep !== undefined || deleteValue !== undefined || props.searchWizard) ? (
        <RealWizard
          {...props}
          dummy={dummy}
          activeStep={realWizardStep}
          deleteValue={deleteValue}
          currency={props.currency}
          searchWizard={!!props.searchWizard}
          searchPage={!!props.searchPage}
        />
      ) : (
        dummy
      )}
    </>
  )
}
