import React, { ReactElement } from 'react'
import classNames from 'classnames'

interface MobileEntryInputProps {
  placeholder: string
  onClick?: () => void
  searchWizard?: boolean
}

const WIZARD_INPUT_STYLES_LANDING_PAGE =
  'inline-flex bg-transparent w-full h-[62px] cursor-black h-full border-l-0 border-t-0 border-b-0 border-r-1 border-r-[rgba(0,0,0,0.2)] text-black border-1 text-xl text-left md:text-lg text-white  md:px-2 font-regular px-3 pt-2 placeholder:text-black-34 focus:outline-none text-ellipsis overflow-hidden grow wml:border-r-0'
const WIZARD_INPUT_STYLES_SEARCH_PAGE =
  'inline-flex bg-transparent w-full h-[62px] cursor-black h-full border-l-0 border-t-0 border-b-0 border-r-1 border-r-[rgba(0,0,0,0.2)] text-black border-1 text-xl text-left md:text-lg text-white  md:px-2 font-regular px-3 pt-2 placeholder:text-black-34 focus:outline-none text-ellipsis overflow-hidden grow wm:border-r-0'

export default function MobileEntryInput(props: MobileEntryInputProps): ReactElement {
  return (
    <div
      className={classNames({
        'relative flex h-[62px] rounded-md bg-white drop-shadow-[0_2px_4px_rgba(0,0,0,0.2)] wd:hidden wm:visible':
          props.searchWizard,
        'relative flex h-[62px] rounded-md bg-white drop-shadow-[0_2px_4px_rgba(0,0,0,0.2)] wdl:hidden wml:visible':
          !props.searchWizard,
      })}
      onClick={(event) => {
        event.stopPropagation()
        const focusHolder = document.querySelector<HTMLInputElement>('#focusHolder')
        if (focusHolder) {
          focusHolder.focus()
        }
        props.onClick && props.onClick()
      }}
    >
      <input
        type="text"
        autoComplete="off"
        className={
          props.searchWizard ? WIZARD_INPUT_STYLES_SEARCH_PAGE : WIZARD_INPUT_STYLES_LANDING_PAGE
        }
        placeholder={props.placeholder}
        readOnly={true}
      />
      <button
        aria-label="Search"
        className="relative bottom-0 right-0 top-0 m-1 w-[76px] rounded-r-lg bg-primary pl-6 pr-12 text-left text-2xl text-white before:absolute before:bottom-0 before:left-7 before:block before:h-[100%] before:w-[23px] before:bg-contain before:bg-center before:bg-no-repeat before:content-[''] before:icon-button-search"
      ></button>
    </div>
  )
}
