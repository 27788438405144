'use client'

import React, { MutableRefObject, ReactElement, ReactNode, useContext } from 'react'
import MobileEntryInput from '@/components/ui/wizard/MobileEntryInput'
import classNames from 'classnames'
import SearchButton from '@/components/ui/wizard/SearchButton'
// import { useTranslate, useWithinGroupB } from '@/lib/hooks'
import { ConfigContext } from '@/components/common/ConfigContext'
import { FORM_ELEMENT_BORDER_COLOR } from '@/components/elements/common-styles'
import { useTranslations } from 'next-intl'

interface WizardWrapperProps {
  activeStep?: number
  onMobileEntryClick?: () => void
  search?: () => void
  wizardRef?: MutableRefObject<HTMLDivElement | null>
  children: ReactNode
  onMouseEnter?: () => void
  searchWizard?: boolean
  closeSearchOptions?: () => void
  showSearch?: () => void
  placeHolderShown?: boolean
}

const GRID_LANDING_PAGE =
  'wdl:relative wdl:h-auto whitespace-nowrap bg-transparent wdl:bg-white wdl:drop-shadow-[0_2px_4px_rgba(0,0,0,0.2)] wdl:rounded-md wdl:grid-cols-[minmax(0px,1fr),minmax(0px,1fr),minmax(0px,1fr),0.5fr]'
const GRID_SEARCH_PAGE_NOT_ACTIVE =
  'wm:h-[54px] items-center lg:border-[1px] rounded-lg lg:rounded-md basis-[500px] wd:h-[44px] ' +
  'shadow-[0_4px_4px_rgba(0,0,0,0.07)] wd:shadow-none border-[1px] border-[#E8E8E8] bg-white lg:bg-transparent'
const GRID_SEARCH_PAGE_NOT_ACTIVE_WITH_PLACEHOLDER = classNames(
  GRID_SEARCH_PAGE_NOT_ACTIVE,
  'wd:grid-cols-[repeat(3,1fr)]',
)
// const GRID_SEARCH_PAGE_NOT_ACTIVE_WITH_OUT_PLACEHOLDER = classNames(GRID_SEARCH_PAGE_NOT_ACTIVE)
const GRID_SEARCH_PAGE_ACTIVE =
  'grid-cols-[repeat(3,1fr)] wd:border-[1px] wd:rounded-md wd:basis-[500px] wd:h-[44px] ' +
  'wd:shadow-none wm:whitespace-nowrap wm:bg-transparent wm:grid-cols-[repeat(1,_1fr_)]'

export default function WizardWrapper(props: WizardWrapperProps): ReactElement {
  const translate = useTranslations('Wizard')
  const skipHeaderAndFooter = useContext(ConfigContext)!.skipHeaderAndFooter
  const mapLayoutABTestGroupB = true && !skipHeaderAndFooter
  //useWithinGroupB(Experiment.SearchWithoutMap) && !skipHeaderAndFooter

  const innerWrapper = (
    <div
      className={classNames('grid h-full', {
        [GRID_LANDING_PAGE]: !props.searchWizard,
        [classNames(FORM_ELEMENT_BORDER_COLOR, {
          'lg:w-[686px]': !mapLayoutABTestGroupB,
          'lg:w-full': mapLayoutABTestGroupB,
        })]: props.searchWizard,
        [GRID_SEARCH_PAGE_NOT_ACTIVE_WITH_PLACEHOLDER]:
          props.searchWizard && props.activeStep === undefined,
        [GRID_SEARCH_PAGE_NOT_ACTIVE_WITH_PLACEHOLDER]:
          props.searchWizard && props.activeStep === undefined && !props.placeHolderShown,
        [classNames(GRID_SEARCH_PAGE_NOT_ACTIVE, {
          'grid-cols-[repeat(5,minmax(_0px,_max-content_))] md:grid-cols-[1fr,1fr,1fr]':
            mapLayoutABTestGroupB,
          'grid-cols-[repeat(5,minmax(_0px,_max-content_))]': !mapLayoutABTestGroupB,
        })]: props.searchWizard && props.activeStep === undefined && props.placeHolderShown,
        [GRID_SEARCH_PAGE_ACTIVE]: props.searchWizard && props.activeStep !== undefined,
      })}
      ref={props.wizardRef}
      onClick={(event) => {
        event.stopPropagation()
        props.closeSearchOptions && props.closeSearchOptions()
        props.showSearch && props.showSearch()
      }}
    >
      {props.children}
      {!props.searchWizard && (
        <SearchButton onClick={props.search} searchWizard={!!props.searchWizard} />
      )}
    </div>
  )

  return (
    <div className={classNames({ 'rounded-md bg-primary-mid p-1.5': !props.searchWizard })}>
      {!props.searchWizard && (
        <MobileEntryInput
          placeholder={translate('destinationLabelMobile')}
          onClick={props.onMobileEntryClick}
          searchWizard={props.searchWizard}
        />
      )}

      <div
        className={classNames('bottom-0 left-0 right-0 top-0 z-50 w-full rounded-md', {
          'bg-white': !props.searchWizard || (props.searchWizard && props.activeStep !== undefined),
          'wm:fixed': props.searchWizard && props.activeStep !== undefined,
          'wml:fixed': !props.searchWizard && props.activeStep !== undefined,
          'col-span-1 wd:bg-transparent': props.searchWizard,
          'min-w-0 wdl:bg-transparent': !props.searchWizard,
          'px-2': props.searchWizard,
        })}
        onMouseEnter={props.onMouseEnter}
      >
        {innerWrapper}
      </div>
    </div>
  )
}
