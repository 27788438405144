'use client'

import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { useTranslations } from 'next-intl'

interface SearchButtonProps {
  onClick?: () => void
  searchWizard: boolean
}

export default function SearchButton(props: SearchButtonProps): ReactElement {
  const t = useTranslations('Wizard')
  return (
    <button
      aria-label="Search"
      className={classNames(
        "relative m-2 rounded-md bg-primary pl-16 pr-12 text-center text-2xl text-white before:absolute before:bottom-0 before:left-5 before:block before:h-[100%] before:w-[21px] before:bg-contain before:bg-center before:bg-no-repeat before:content-[''] before:icon-button-search",
        { 'wm:hidden': props.searchWizard, 'wml:hidden': !props.searchWizard },
      )}
      onClick={props.onClick}
    >
      {t('searchButton')}
    </button>
  )
}
